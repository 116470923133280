import { CSSProperties, ReactNode, forwardRef } from 'react';
import cn from 'classnames';
import styles from './ProductGrid.module.scss';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

type ProductGridProps = {
	children: ReactNode;
	type?: 'tops' | 'base' | 'all-tops' | 'accessory' | 'build-flow' | 'tops-single' | 'build-flow-with-subscription';
	fullWidth?: boolean;
	title?: string;
	style?: CSSProperties;
	className?: string,
};

const ProductGrid = forwardRef<HTMLDivElement, ProductGridProps>(({ title, children, type = 'tops', style, ...rest }: ProductGridProps, ref) => {
	const isBFAllTopsTestActive = useFeatureIsOn('is-bf-all-tops-test');
	const classes = cn(rest.className, {
		[styles['base']]: type === 'base',
		[styles['tops']]: type === 'tops',
		[styles['all-tops']]: type === 'all-tops',
		[styles['accessory']]: type === 'accessory',
		[styles['build-flow']]: type === 'build-flow' || type === 'build-flow-with-subscription',
		[styles['build-flow--withSubscription-fullWidth']]: type === 'build-flow-with-subscription' && isBFAllTopsTestActive,
		[styles['build-flow--withSubscription']]: type === 'build-flow-with-subscription',
		[styles['tops--singleCol']]: type === 'tops-single',
	});
	delete rest.className;

	return (
		<div ref={ref} data-test-collection={title} className={classes} style={style} {...rest}>
			{children}
		</div>
	);
});

ProductGrid.displayName = 'ProductGrid';

export default ProductGrid;
