import cn from 'classnames';
import { VerticalCard } from '@components/card';
import { ProductGrid } from '@components/collections';
import { Button, Flex, Grid, Img, Lozenge, Spacer } from '@components/common';
import { Heading, Label, Paragraph } from '@components/typography';
import { useIsMobile } from '@utils/hooks';
import { NormalizedCollection, NormalizedProduct, NormalizedVariant } from '@ts/index';
import variables from '@styles/export.module.scss';
import styles from './TopsCollectionPreview.module.scss';

type TopsCollectionPreviewProps = {
	collection: Omit<NormalizedCollection, 'products'> & { products: NormalizedVariant[] };
	subBannerBackgroundColor?: string;
	centerHeader?: boolean;
	productCount?: number;
	subBanner?: string;
	extraClasses?: string;
};


const TopsCollectionPreview = ({
	centerHeader = false,
	subBanner,
	subBannerBackgroundColor,
	collection,
	productCount = 8,
	extraClasses,
}: TopsCollectionPreviewProps) => {
	const { handle, title, metafields, products } = collection;
	const visibleProducts = products.filter(product => product.availableForSale);
	const hasAvailableProducts = visibleProducts?.length > 0;
	const limitedProducts = visibleProducts.length > productCount ? visibleProducts.slice(0, productCount) : visibleProducts;
	const isMobile = useIsMobile();

	const productGridClasses = cn(styles.productGrid, extraClasses)

	const mappedProducts = hasAvailableProducts
		? limitedProducts.map(variant => {
			return (
				<VerticalCard
					key={variant.id}
					product={variant.product as NormalizedProduct}
					variant={variant}
					parentCollectionHandle={collection.handle}
					secondaryAction='favorite'
					compact={isMobile}
					showTags
					showCollectionLozenge={false}
				/>
			);
		})
		: null;

	return (
		<Flex
			className={styles.container}
			fullHeight
			fullWidth
			maxWidth
			center
			column
			data-tops-collection-preview={collection.handle}
		>
			<Flex
				justify='between'
				align='end'
				className={`${styles.collectionHeader} ${centerHeader && styles.centerHeader} `}

			>
				<Flex column fullHeight fullWidth align='start' justify={metafields?.collectionLogoUrl ? 'center' : 'end'} pad={4} className={cn(styles.collectionNameContainer, { [styles['collectionNameContainer--gradient']]: !metafields?.collectionLogoUrl })}>
					{metafields?.collectionLogoUrl ? (
						<div className={styles.collectionIcon}>
							<Img
								src={metafields.collectionLogoUrl.split('?')[0]}
								alt='collection logo'
								width={100}
								height={100}
							/>
						</div>
					) : (
						<div className={styles.collectionName}>
							{metafields?.isLimited ? (
								<Lozenge backgroundColor={variables.yellow2} color='#000' shape='square'>
									<Label small>Limited Edition</Label>
								</Lozenge>
							) : null}
							{centerHeader ? (
								<Heading tag='h4' className={styles.holidayCollection}>
									{title}
								</Heading>
							) : (
								<Heading tag='h5' removeDefaultMargin>{title}</Heading>
							)}
						</div>
					)}
				</Flex>
				{!centerHeader && (
					<Button href={`/top-frames/${handle}`} data-view-more={handle} color='white' size='medium'>
						View More
					</Button>
				)}
				<Img
					src={collection?.image?.url}
					alt={collection?.image?.url}
					width={720}
					height={96}
				/>
			</Flex>

			{subBanner?.length > 0 && subBannerBackgroundColor && (
				<div
					className={styles.subBannerContainer}
					style={{
						backgroundColor: subBannerBackgroundColor,
					}}
				>
					<Paragraph>{subBanner}</Paragraph>
				</div>
			)}
			<Spacer size={8} />
			<Grid className={limitedProducts?.length > 0 ? productGridClasses : styles.emptyCardContainer} >
				{hasAvailableProducts ? (
					<>
						<ProductGrid type='all-tops'>{mappedProducts}</ProductGrid>
					</>
				) : (
					<Paragraph className={styles.noResults} large>
						No tops available for your selected frame shape
					</Paragraph>
				)}
			</Grid>
			<Spacer size={8} />
			<Button 
				linkStyle 
				extraClasses={styles.button}
				href={`/top-frames/${handle}`} 
				title={`View More of the ${collection.title} Collection!`}
			>
				View More
			</Button>
		</Flex>
	);
};

export default TopsCollectionPreview;
